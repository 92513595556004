/*============================================================================
    Main, elements
 ============================================================================*/

//@import url('https://fonts.googleapis.com/css?family=Comfortaa|Quattrocento');
@import url('https://fonts.googleapis.com/css?family=Lora|Open+Sans');

// add global variables for use
@import "components/variables";

/*@import url('./assets/gooey_loader.css');*/
///*font-family: Roboto, Arial, Helvetica, sans-serif;*/
//font-family: 'Open Sans', sans-serif;
//font-family: 'Lora', serif;

/*============================================================================
    Font Variables
 ============================================================================*/

//$FONT_HEADERS: 'Comfortaa', cursive;
//$FONT_TEXT: 'Quattrocento', serif;

//$FONT_HEADERS: 'Open Sans', sans-serif;
//$FONT_TEXT: 'Lora', serif;
$TABLE_BORDER_COLOR : #a6a6a6;

/*============================================================================
    Elements
 ============================================================================*/

body, html{
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-size: cover;
    background-color: rgba(0,0,0,0.04);
    font-family: "Lato", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    //color: #369;
    font-family: "Lato", sans-serif;
    //font-size: 250%;
}

p{
    font-family: "Lato", sans-serif;
    font-size: 1.2em;
}

strong {
    font-weight: bold;
}

/*============================================================================
    Globals
 ============================================================================*/

.boat-type-image{
    //width: 100%;
    margin-bottom: 15px;
    display: inline-block;
    //max-width: 400px;
    //display: inline-block;
    //width: auto;
}
.pseudo-table{
    padding-left: 8px;
}
.pseudo-header {
    //margin-bottom: 30px;
    padding-left: 8px;
}

.table {
    border-color: $TABLE_BORDER_COLOR !important;
    td {
        border-color: $TABLE_BORDER_COLOR !important;
    }
    th {
        border-color: $TABLE_BORDER_COLOR !important;
    }
}

.btn-snapscan{
    margin-top: 15px;
}

// defines styling for all user entered rich text such as the video intro text and fisher blurbs
.rich-text{
    a{
        color: $link-color;
    }
    a:hover{
        text-decoration: underline;
    }
}

.rich-text-truncate{
    a{
        color: $link-color;
    }
    a:hover{
        text-decoration: underline;
    }

    overflow: hidden;
    position: relative;
    line-height: 1.4em;
    max-height: 4.2em;
    margin-right: -1em;
    padding-right: 1em;
}

.rich-text-truncate+.rich-text-truncate{
    margin-top:10px;
}
.rich-text-truncate:before {
    content: '...';
    position: absolute;
    right: 0.5em;
    bottom: 0;
}
.rich-text-truncate:after {
    content: '';
    position: absolute;
    right: 0.5em;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: #fbfbfb;
}


/*============================================================================
    Utility
 ============================================================================*/

.cs-centered{
    margin-top: 35vh;
}

.cs-clean{
    margin: 0;
    padding: 0;
}

.cs-spacer{
    height: 20px;
}

.generic-full-background{
    /*position: relative;*/
    /*width: 100vw;*/
    min-height: 100vh;
    background-size: cover;
}

.animate{
    will-change: transform, opacity;
}
.card {
    background: rgba(255,255,255,0.8);
    border-radius: 2px;
    /*display: inline-block;*/
    /*height: 300px;*/
    /*margin: 1rem;*/
    /*position: relative;*/
    /*width: 300px;*/
}

.card-dark {
    background: rgba(0,0,0,0.8);
}

.card-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}


/* mobile modal improvements */

@media (max-width: 768px) {
    .modal {
        width: 95% !important;
        min-height: 95% !important;
        top: 2.5% !important;
    }
}
